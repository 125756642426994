const EN_US = {
  'catalogAdminDomain.title': 'Catalog Admin',
  'catalogAdminDomain.imageManagement.title': 'Fix Image Alert',
  'catalogAdminDomain.imageUploads.title': 'Image uploads',

  // Catalog attribute management Platform
  'catalogAdminDomain.attributeCreationPlatform.nav.title': 'Attribute Creation Platform',
  'catalogAdminDomain.attributeCreationPlatform.table.filters.id': 'Filter By Id',
  'catalogAdminDomain.attributeCreationPlatform.table.filters.name': 'Filter By Name',
  'catalogAdminDomain.attributeCreationPlatform.table.filters.owner': 'Filter By Owner',
  'catalogAdminDomain.extractionModels.nav.title': 'Extraction models',
  'catalogAdminDomain.extractionModels.table.headers.id': 'ID',
  'catalogAdminDomain.extractionModels.table.headers.name': 'Name',
  'catalogAdminDomain.extractionModels.table.headers.version': 'Version',
  'catalogAdminDomain.extractionModels.table.headers.type': 'Type',
  'catalogAdminDomain.extractionModels.table.headers.type.help': 'Displays the type of model.',
  'catalogAdminDomain.extractionModels.table.headers.owner': 'Owner',
  'catalogAdminDomain.extractionModels.table.headers.actions': 'Actions',
  'catalogAdminDomain.extractionModels.table.headers.activeVersion': 'Active : ',
  'catalogAdminDomain.extractionModels.table.headers.inDevelopmentVersion': 'In development : ',
  'catalogAdminDomain.extractionModels.table.actions.edit': 'Edit Models',
  'catalogAdminDomain.extractionModels.table.actions.viewDetails': 'View Details',
  'catalogAdminDomain.extractionModels.createButton': 'Create',
  'catalogAdminDomain.extractionModels.modal.title': 'Edit Extraction Model',
  'catalogAdminDomain.extractionModels.modal.cancel': 'Cancel',
  'catalogAdminDomain.extractionModels.modal.reset': 'Reset',
  'catalogAdminDomain.extractionModels.modal.createNewVersion': 'Create New Version',
  'catalogAdminDomain.extractionModels.modal.reason': 'Reason',
  'catalogAdminDomain.extractionModels.modal.reasonPlaceholder': 'Enter reason for the change',
  'catalogAdminDomain.extractionModels.modal.model': 'LLM Model',
  'catalogAdminDomain.extractionModels.modal.modelPlaceholder': 'Select LLM Model',
  'catalogAdminDomain.extractionModels.modal.description': 'Description',
  'catalogAdminDomain.extractionModels.modal.prompt': 'Prompt',
  'catalogAdminDomain.extractionModels.modal.table.headers.propertyName': 'Property Name',
  'catalogAdminDomain.extractionModels.modal.table.headers.required': 'Required',
  'catalogAdminDomain.extractionModels.modal.table.headers.images': 'Images',
  'catalogAdminDomain.extractionModels.modal.createdBy.subtitle':
    'Enter a team name or point of contact here',
  'catalogAdminDomain.extractionModels.createdBy': 'Created By',
  'catalogAdminDomain.extractionModels.create.nav.title': 'Create New Extraction Model',
  'catalogAdminDomain.extractionModels.create.title': 'Create a new Extraction Model',
  'catalogAdminDomain.extractionModels.create.label.name': 'Name',
  'catalogAdminDomain.extractionModels.create.label.owner': 'Owner',
  'catalogAdminDomain.extractionModels.create.label.description': 'Description',
  'catalogAdminDomain.extractionModels.create.label.description.optional': 'Optional',
  'catalogAdminDomain.extractionModels.create.label.prompt': 'Prompt Template',
  'catalogAdminDomain.extractionModels.create.label.model': 'LLM Model selection',
  'catalogAdminDomain.extractionModels.create.label.properties': 'Properties',
  'catalogAdminDomain.extractionModels.create.button.cancel': 'Cancel',
  'catalogAdminDomain.extractionModels.create.button.create': 'Create',
  'catalogAdminDomain.extractionModels.create.property.warning.message':
    'Property name already exists',
  'catalogAdminDomain.extractionModels.create.label.owner.subtitle':
    'Enter a team name or point of contact here',
  'catalogAdminDomain.extractionModels.create.label.property.subtitle':
    'These properties will be passed along with your prompt template to the LLM. You can mention these properties in your prompt to signal to the AI how to use them',
  'catalogAdminDomain.extractionModels.label.required.help':
    'If required, a prompt will not go to the LLM for a product if that property is not provided. This can be used to save cost when a critical property is missing',
  'catalogAdminDomain.extractionModels.label.images.help':
    'indicates if this property is an image URL or series of image URLs. LLMs treat images differently so we need to flag them as such.',
  'catalogAdminDomain.extractionModels.property.input.placeholder': 'Enter new property name',
  'catalogAdminDomain.extractionModels.property.input.button.add': 'Add',
  'catalogAdminDomain.extractionModels.property.delete': 'Delete',
  'catalogAdminDomain.extractionModels.table.property.empty.message': 'No properties added yet',
  'catalogAdminDomain.extractionModelsDetails.tab.properties': 'Properties',
  'catalogAdminDomain.extractionModelsDetails.tab.changes': 'Changes',
  'catalogAdminDomain.extractionModelsDetails.tab.results': 'Results',
  'catalogAdminDomain.extractionModelsDetails.button.live': 'View Live',
  'catalogAdminDomain.extractionModelsDetails.button.latest': 'View Latest',
  'catalogAdminDomain.extractionModelsDetails.changes.button.makeLive': 'Make Live',
  'catalogAdminDomain.extractionModelsDetails.changes.button.tombstone': 'Tombstone',
  'catalogAdminDomain.extractionModelsDetails.modal.title': 'View Changes',
  'catalogAdminDomain.extractionModels.topSectionHeader': 'Details',
  'catalogAdminDomain.extractionModels.bottomSectionHeader': 'LLM Model',
  'catalogAdminDomain.extractionModelsDetails.properties.button.create': 'Create Version',
  'catalogAdminDomain.extractionModelsDetails.properties.version': 'Version',
  'catalogAdminDomain.extractionModelsDetails.runResults.table.headers.id': 'Id',
  'catalogAdminDomain.extractionModelsDetails.runResults.table.headers.requester': 'Requester',
  'catalogAdminDomain.extractionModelsDetails.runResults.table.headers.version': 'Version',
  'catalogAdminDomain.extractionModelsDetails.runResults.table.headers.completed': 'Completed',
  'catalogAdminDomain.extractionModelsDetails.runResults.table.headers.productCount':
    'Product count',
  'catalogAdminDomain.extractionModelsDetails.runResults.table.headers.downloadCSV': 'Download CSV',
  'catalogAdminDomain.extractionModelsDetails.runResults.table.headers.startTime': 'Start Time',
  'catalogAdminDomain.extractionModelsDetails.runResults.modal.button.runManualTest':
    'Run manual test',
  'catalogAdminDomain.extractionModelsDetails.runResults.modal.body.description':
    'You can upload a CSV file with less than 100 rows to run a test.',
  'catalogAdminDomain.extractionModelsDetails.runResults.modal.body.template.description':
    'The file should contain the following columns:',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.noFileSelected':
    'No file selected',
  'catalogAdminDomain.extractionModelsDetails.runResults.modal.body.button.upload': 'Upload file',
  'catalogAdminDomain.extractionModelsDetails.runResults.modal.body.button.download':
    'Download Template',
  'catalogAdminDomain.extractionModelsDetails.runResults.modal.body.button.update': 'Update',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.title': 'Run Test',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.button.submit': 'Submit',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.button.cancel': 'Cancel',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.button': 'Choose file',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.dragDrop':
    'Drag and Drop file here',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.or': 'or',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.error.fileTypeError':
    'Incorrect file type uploaded',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.error.fileContentError':
    'Invalid file uploaded',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.error.uploadError':
    'Error uploading file',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.error.error':
    'There was an error',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.error.retry': 'Retry',
  'catalogAdminDomain.extractionModelsDetails.runTests.modal.upload.error.rowError':
    'The number of rows should not exceed 100',

  // Header Component
  'catalogAdminDomain.imageManagement.header.changeNotification': 'Change Email Notification',
  'catalogAdminDomain.imageManagement.header.settings': 'Settings',

  // List Component
  'catalogAdminDomain.imageManagement.list.title': 'Fix Image Alert',
  'catalogAdminDomain.imageManagement.list.columns.productImage': 'Product Image',
  'catalogAdminDomain.imageManagement.list.columns.productName': 'Product Name',
  'catalogAdminDomain.imageManagement.list.columns.upc': 'UPC Code',
  'catalogAdminDomain.imageManagement.list.columns.rrc': 'RRC Code',
  'catalogAdminDomain.imageManagement.list.columns.imageUrl': 'Image Url',
  'catalogAdminDomain.imageManagement.list.columns.imageProblem': 'Image Problem',
  'catalogAdminDomain.imageManagement.list.columns.removalDeadline': 'Removal Deadline',
  'catalogAdminDomain.imageManagement.list.actions': 'Actions',
  'catalogAdminDomain.imageManagement.list.viewImage': 'View Image',
  'catalogAdminDomain.imageManagement.list.moreActions': 'More Actions',
  'catalogAdminDomain.imageManagement.list.actions.removeImage': 'Remove/Hide Image',
  'catalogAdminDomain.imageManagement.list.actions.delayRemoval': 'Delay Removal By 14 Days',
  'catalogAdminDomain.imageManagement.list.actions.falsePositive': 'Mark As False Positive',
  'catalogAdminDomain.imageManagement.list.actions.editProduct': 'Edit Product',

  // Modal Component
  'catalogAdminDomain.imageManagement.modal.title': 'Settings',
  'catalogAdminDomain.imageManagement.modal.optOut': 'Opt Out',
  'catalogAdminDomain.imageManagement.modal.delayRemoval': 'Delay Removal',

  // Product Edit
  'catalogAdminDomain.productEdit.nav.title': 'Product edit',
  'catalogAdminDomain.productEdit.show.breadcrumb.root': 'Product Edit',
  'catalogAdminDomain.productEdit.show.search.placeholder': 'Product ID',
  'catalogAdminDomain.productEdit.show.search.button': 'Edit',
  'catalogAdminDomain.ProductEdit.show.title': 'Product',
  'catalogAdminDomain.ProductEdit.show.emptyProduct': 'Please, enter a Product ID.',
  'catalogAdminDomain.ProductEdit.show.noProductFound': 'Product not found.',

  // MD Platform
  'catalogAdminDomain.mdPlatform.nav.title': 'Measure & detect platform',
  'catalogAdminDomain.mdPlatform.create.nav.title': 'Create New Detection',
  'catalogAdminDomain.mdPlatform.create.title': 'Create a New Detection',
  'catalogAdminDomain.mdPlatform.create.label.name': 'Detection Name',
  'catalogAdminDomain.mdPlatform.create.label.description': 'Description',
  'catalogAdminDomain.mdPlatform.create.label.limit': 'Limit',
  'catalogAdminDomain.mdPlatform.create.label.defectTag': 'Fix Defect Tag',
  'catalogAdminDomain.mdPlatform.create.label.owner': 'Owner',
  'catalogAdminDomain.mdPlatform.create.label.enable': 'Enable',
  'catalogAdminDomain.mdPlatform.create.label.query': 'Query',
  'catalogAdminDomain.mdPlatform.create.button.cancel': 'Cancel',
  'catalogAdminDomain.mdPlatform.create.button.create': 'Create',

  // Quality Audit Workflows
  'catalogAdminDomain.qualityAuditWorkflows.nav.title': 'Quality Audit Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.title': 'Quality Audit Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.motd':
    'Welcome to the Quality Audit System (QAS)! Here, you can audit and correct any dataset you choose. Click the button below to learn more.',
  'catalogAdminDomain.qualityAuditWorkflows.list.learnMore': 'Learn More',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.launch': 'Launch',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.workflow': 'Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.taskCount': 'Task Count',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.source': 'Source',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.notifyUpdateSuccessMsg':
    'Update published.',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.notifyUpdateErrorMsg':
    'Update failed, please try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.dueDate': 'Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.actions': 'Actions',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.productSources': 'Product Sources',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.productMatches': 'Product Matches',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.value.view': 'View',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.inputData': 'Input Data',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.inputData.value': 'View',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.addToMyWorkflows':
    'Add To My Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.comment':
    'Set Internal/External Comments',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.flagReject': 'Reject All Values',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.flagAccept': 'Accept All Values',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.viewInputData': 'View Input Data',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.removeFromMyWorkflows':
    'Remove From My Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.subscribeToNotifications':
    'Subscribe To Notifications',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.unsubscribeToNotifications':
    'Unsubscribe To Notifications',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.outsourceWorkflows.title':
    'Outsource Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.problemTrackerWorkflows.title':
    'Problem Tracker Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.automatedDetectWorkflows.title':
    'Automated Detect Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.humanEvalAutomatedFixIngestionWorkflows.title':
    'Human Eval Automated Fix Ingestion Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.automatedFixWorkflows.title':
    'Automated Fix Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.measureAndDetect.title':
    'Measure And Detect',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.imageAttributesExtractionWorkflows.title':
    'Image Attributes Extraction',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.mlWorkflows.title': 'ML Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.myWorkflows.title': 'My Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.actions.submitAuditTasks': 'Submit Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.list.actions.modifyWorkflow': 'Modify Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.list.actions.createWorkflow': 'Create Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.title': 'Submit Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.task.error.unexpectedError':
    'There was an unexpected error processing your submission. Please contact support for assistance or try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.task.error.legacyWorkflow':
    'This is a legacy workflow, please contact support to make changes here.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.progressbar.text.setup':
    'Setup Task Submission (Step 1 of 2)',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.progressbar.text.loading':
    'Loading Sample Data...',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.progressbar.text.review':
    'Review Sample Rows (Step 1 of 2)',
  'catalogAdminDomain.queryAuditWorkflows.task.submit.form.workflow.placeholder': 'Select Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.createdWhy.label': 'Reason:',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.createdWhy.explanation':
    'Explanation for creating this batch.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.maxBatchNum.label':
    'Max Number Of Batches:',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.maxBatchNum.explanation':
    'The max number of batches to create based on SQL provided.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.batchSize.label': 'Batch Size:',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.batchSize.explanation':
    'The max number of tasks that this batch will contain.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.description.label': 'Description',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.description.explanation':
    'Explain the details of these tasks.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.samplingStrategy.label':
    'Sampling Strategy',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.samplingStrategy.explanation':
    '(Optional) Use a sampling function to select key tasks from your query.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.label': 'Batch Query',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.alert':
    'To learn how to structure your query, read the details ',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.alertHere': 'here.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.explanation':
    'Enter a Snowflake query to create the tasks. Note: Previewing has a 5-minute execution timeout, and submission has a 30-minute timeout.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.button.continue': 'Continue',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.button.cancel': 'Cancel',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.grid.header.entity': 'Entity',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.grid.header.workflowId':
    'Workflow ID',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.preview': 'Preview',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.submit': 'Submit',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.back': 'Back',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.cancel': 'Cancel',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.submit.addAnother':
    'Submit and add more',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.title': 'Filter Batch State:',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.pastDue': 'Past Due',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.pending': 'Pending',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.completed': 'Completed',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskSecondaryState.title':
    'Filter Batch Status:',
  'catalogAdminDomain.qualityAuditWorkflows.select.assignee.title': 'Filter By Assignee:',
  'catalogAdminDomain.qualityAuditWorkflows.batch.submissionInfo':
    'This is a filtered view showing batches created from the submission {submissionUuid}. If you do not see any batches, please wait a moment for them to be generated. Otherwise, feel free to contact support with this url for further investigation.',
  'catalogAdminDomain.qualityAuditWorkflows.select.reviewer.title': 'Filter By Reviewer:',
  'catalogAdminDomain.qualityAuditWorkflows.select.description.title': 'Filter By Description:',
  'catalogAdminDomain.qualityAuditWorkflows.select.workflow.title': 'Workflow Type',
  'catalogAdminDomain.qualityAuditWorkflows.graph.title': 'Task Count',
  'catalogAdminDomain.qualityAuditWorkflows.graph.seriesName': 'Task Count',
  'catalogAdminDomain.qualityAuditWorkflows.graph.category.approvedTasks': 'Approved Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.graph.category.rejectedTasks': 'Rejected Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.graph.category.auditableTasks': 'Auditable Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.notifyFetchErrorMsg':
    'Momentary network issue occurred. If this persists, please contact support.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.collecting':
    'The batch is still being created. Please check back later.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.notifyFetchSuccessMsg':
    'Network connectivity established.',
  'catalogAdminDomain.qualityAuditWorkflows.range.title': 'Filter Due Date:',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reassign.title': 'Select Reassignment',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reassign.select.placeholder':
    'Select Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.reassign': 'Reassign',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.combine.title': 'Confirm Combine',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.combine.body':
    'You are about to combine several batches, please confirm action.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.byPass.title': 'Confirm Release',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.byPass.body':
    'You are about to release the selected batches.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.dueDate.title': 'Override Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reject.title': 'Confirm Rejection',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reject.body':
    'You are about to reject the following batch.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.sample.title': 'Override Sample Size',
  'catalogAdminDomain.qualityAuditWorkflows.batch.title': 'Batch Management',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.pending': 'Pending',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.rejected': 'Rejected',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.approved': 'Approved',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.bypassed': 'By Passed',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.force_rejected': 'Force Rejected',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.collectingTasks': 'Collecting Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.errored': 'Errored',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.rework.title': 'Rework Batches for Audit',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.title': 'Workflow Batches for Audit',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.launch': 'Launch',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.batchNumber': 'Batch Number',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.description': 'Description',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.received': 'Received',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.recommendedSample':
    'Recommended Sample',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.sampleSize': 'Sample Size',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.numberAuditable': '# Auditable',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.dueDate': 'Due Date (UTC)',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.createdAt': 'Created At (UTC)',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.actions': 'Actions',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.status': 'Status',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.assignee': 'Assignee',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.reviewer': 'Reviewer',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.action.overrideSampleSize':
    'Override Sample Size',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.action.rejectBatch': 'Reject Batch',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.action.overrideDueDate':
    'Override Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.release': 'Release Without Audit',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.combine': 'Combine',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.launch': 'Launch Audit',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.title': 'Legend:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.valid': 'Valid Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.validWithSuggestedValue':
    'Valid Value With Suggested Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.validComments':
    'Valid Value With Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.invalid': 'Invalid Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.batchState': 'Batch State:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.batchSecondaryStatus': 'Batch Workflow Status:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.createdAt': 'Created At:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.vendor': 'Vendor:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.reviewer': 'Reviewer:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.assignee': 'Assignee:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.completedAt': 'Completed At:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.title': 'Tasks for Auditing',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.button.batchComments': 'Batch Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.button.submit': 'Submit Completed Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.dueDate': 'Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.setFlag': 'Set Flag:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.imageSize': 'Image Size:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.columnWidth': 'Set Width:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.filterState': 'Filter State:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.pendingState': 'Set Pending:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.acceptTask': 'Accept Task',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.rejectTask': 'Reject Task',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.resetTask': 'Reset Task',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.state': 'State',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.entityId': 'Entity Id',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.productId': 'Product Id',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.retailerProductId':
    'Retailer Product Id',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.upc': 'UPC Code',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.image': 'Image',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.freeze': 'Freeze Column',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.unfreeze': 'Unfreeze Column',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.actions.leaveComments':
    'Leave Internal/External Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.flagValue': 'Flag Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.flagValueWithSuggestValue':
    'Flag Value With Suggested Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.comment': 'Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.title.comments': 'Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.title.input': 'Input Data',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.value': 'Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.flag': 'Flag',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.submit': 'Submit',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.close': 'Close',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.cancel': 'Cancel',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.valid': 'Valid',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.faq': 'FAQ',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.invalid': 'Invalid',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.existingInternalComment':
    'Existing Internal Comment',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.existingExternalComment':
    'Existing External Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.internalComment': 'Internal Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.externalComment': 'External Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.optional': 'Optional',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.suggestedValue': 'Suggested Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.notFound': 'No input data found',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.errorLoading':
    'Error while loading input data',
  'catalogAdminDomain.qualityAuditWorkflows.modify.title': 'Modify Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.modify.selectWorkflow': 'Select Workflow:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.samplePercentage.title': 'Sample Percentage:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.samplePercentage.details':
    'Percentage sample amount for a batch.',
  'catalogAdminDomain.qualityAuditWorkflows.modify.failurePercentage.title': 'Failure Percentage:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.failurePercentage.details':
    'Maximum failure percentage for a batch.',
  'catalogAdminDomain.qualityAuditWorkflows.modify.slaDays.title': 'Default SLA Days:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.slaDays.details':
    'The expected number of days to finish tasks.',
  'catalogAdminDomain.qualityAuditWorkflows.modify.features.title': 'Features:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.features.details':
    'Apply specific features to your workflow.',
  'catalogAdminDomain.qualityAuditWorkflows.modify.columns.title': 'Columns:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.columns.details':
    'Configure how the columns will be displayed on the workflow.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.errorMessage':
    'Unable to update batch, due to an issue, please try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.numOfBatches': 'Number Of Batches',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.numOfBatchesInfo':
    'The number of batches to create.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.targetColumn': 'Target Column',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.targetColumnInfo':
    'Choose a column to calculate GMV or impressions. The chosen column must exist in your query so we can set the sampling weight.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.sampleOrder': 'Sample Order',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.sampleOrderInfo':
    'Select the order on how we sample tasks.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.dateRange': 'Date Range',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.dateRangeInfo':
    'Specify a time range to calculate GMV or impressions.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.portionSize': 'Population Size',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.portionSizeInfo':
    '(Optional) The total number of subjects.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.portion': 'Population Portion',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.portionInfo':
    'The percentage of entries to select.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.confidence': 'Confidence Percentage',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.confidenceInfo':
    'The confidence level represents the probability that the true value falls within a specified range, meaning a 95% confidence level indicates a 95% chance the real value is within that range and a 5% chance it is outside.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.error': 'Error Percentage',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.errorInfo':
    'The margin of error expresses sampling error.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.title': 'Select Strategy',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.info':
    "The provided query will be modified to include an additional logic that calculates a sampling strategy to select the preferred entities. If you've already applied a sampling strategy, please remove it before proceeding.",
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.flagAll': 'Flag All',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.clearFlags': 'Clear Flags',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.errorMessage':
    'Unable to save, because a conflict has occurred. Please, review and try again.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.conflictMessage':
    'There was an update to the existing comments, please review it before saving.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.title.change':
    'Change Input',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.title.create': 'New Input',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.info':
    'Ensure the provided values exactly match the values in your submissions. ',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.type.label': 'Type:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.type.details':
    'Type of component.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.name.label': 'Name:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.name.details':
    'Name of the component.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.groupName.label':
    'Group Name:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.groupName.details':
    'Identifier for a set of components.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.apply': 'Apply',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.save': 'Save',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.close': 'Close',
  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.title': 'Feature',

  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.name.label': 'Name:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.name.details':
    'The provided name should match one from this list.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.value.label': 'Value:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.value.details':
    'Depending on the feature name you entered, you may need to provide a value. (Optional)',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.or.label': 'Or',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.title': 'Column Config',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.alias.label': 'Alias:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.alias.details':
    'Name of the column.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.render.label': 'Render:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.render.details':
    'Specifies how to display the column values.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.inputs.label': 'Inputs:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.inputs.details':
    'Determines which values to display in the column.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.inputs.flaggable': 'Flaggable',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.inputs.suggestible':
    'Suggestible',

  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.tab.currentData': 'New Data',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.tab.conflictingData': 'Conflicting Data',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.nav.title':
    'Quality Audit Create Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.title': 'Create Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.error':
    'Unable to create workflow, please try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.explanation': `
        Workflow names are a combination of three parts: Domain, Service, and Type or Workflow. For
        instance, "outsource/scale/alcohol" indicates that the data pertains to auditing
        alcohol-related data from Scale on the outsourcing platform.`,
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.label': 'Name',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.domain': '1. Domain',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.domainDetails': `The name of the subject to which the data pertains. For example, enrichment or outsourcing.`,
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.service': '2. Service',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.serviceDetails': `The name of the service or vendor from which the data originates. For example, Scale or ML.`,
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.workflow':
    '3. Workflow/Type',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.workflowDetails': `The name of the workflow. For example, Taxonomy or Images.`,
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.placeholder':
    'Workflow Name',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.batchSamplePercentage.label':
    'Batch Sample Percentage',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.batchFailurePercentage.label':
    'Batch Failure Percentage',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.defaultSLADelayDays.label':
    'SLA Delay in Days',
  'catalogAdminDomain.imageUploads.productNotFound': 'Item not found',
  'catalogAdminDomain.imageUploads.invalidImage': 'Failed to save image',
  'catalogAdminDomain.mdPlatformList.table.filters.id': 'Filter By Id',
  'catalogAdminDomain.mdPlatformList.table.filters.name': 'Filter By Name',
  'catalogAdminDomain.mdPlatformList.table.filters.owner': 'Filter By Owner',
  'catalogAdminDomain.mdPlatformList.motd.button': 'Learn More',
  'catalogAdminDomain.mdPlatformList.motd':
    "Welcome to the Catalog's measure and detect platform! Here, you can create detection queries to find problematic issues in Catalog. You can learn more by clicking on the button below.",
  'catalogAdminDomain.mdPlatformList.createButton': 'Create New Detection',
  'catalogAdminDomain.mdPlatformList.notify.errorMessage':
    'Momentary network issue occurred. If this persists, please contact support.',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.inReview': 'IN REVIEW',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.testing': 'TESTING',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.rejected': 'REJECTED',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.approved': 'APPROVED',
  'catalogAdminDomain.mdPlatformList.table.details.label': 'Expand for more details',
  'catalogAdminDomain.mdPlatformList.table.query': 'Query:',
  'catalogAdminDomain.mdPlatformList.table.createdBy': 'Created By:',
  'catalogAdminDomain.mdPlatformList.table.createdWhy': 'Created Why:',
  'catalogAdminDomain.mdPlatformList.table.values.none': 'None',
  'catalogAdminDomain.mdPlatformList.table.headers.id': 'Id',
  'catalogAdminDomain.mdPlatformList.table.headers.name': 'Name',
  'catalogAdminDomain.mdPlatformList.table.headers.version': 'Version',
  'catalogAdminDomain.mdPlatformList.table.headers.enabled': 'Enabled',
  'catalogAdminDomain.mdPlatformList.table.headers.enabled.help':
    'Displays if this detection is currently running or not.',
  'catalogAdminDomain.mdPlatformList.table.headers.limit': 'Limit',
  'catalogAdminDomain.mdPlatformList.table.headers.limit.help':
    'The max number of requests we can send',
  'catalogAdminDomain.mdPlatformList.table.headers.owner': 'Owner',
  'catalogAdminDomain.mdPlatformList.table.headers.status': 'Review Status',
  'catalogAdminDomain.mdPlatformList.table.headers.status.help':
    'Displays the state this version is in.',
  'catalogAdminDomain.mdPlatformList.table.headers.actions': 'Actions',
  'catalogAdminDomain.mdPlatformList.title': 'Measure And Detect Platform',
  'catalogAdminDomain.mdPlatformList.table.actions.edit': 'Edit Detection',
  'catalogAdminDomain.mdPlatformList.table.actions.details': 'View Details',
  'catalogAdminDomain.mdPlatformList.modal.cancel': 'Cancel',
  'catalogAdminDomain.mdPlatformList.modal.title': 'Modify',
  'catalogAdminDomain.mdPlatformList.modal.submit': 'Submit',
  'catalogAdminDomain.mdPlatformList.modal.reset': 'Reset',
  'catalogAdminDomain.mdPlatformList.modal.input.reason': 'Reason:',
  'catalogAdminDomain.mdPlatformList.modal.input.reason.placeholder':
    'Enter reason for the change:',
  'catalogAdminDomain.mdPlatformList.modal.input.limit': 'Limit:',
  'catalogAdminDomain.mdPlatformList.modal.input.enabled': 'Enabled:',
  'catalogAdminDomain.mdPlatformList.modal.input.defectTag': 'Defect Tag:',
  'catalogAdminDomain.mdPlatformList.modal.input.cadence': 'Cadence:',
  'catalogAdminDomain.mdPlatformList.modal.input.owner': 'Owner:',
  'catalogAdminDomain.mdPlatformList.modal.input.description': 'Description:',
  'catalogAdminDomain.mdPlatformList.modal.error.create': 'Unable to get detection information',
  'catalogAdminDomain.mdPlatformList.modal.error.get': 'Unable to create new detection version',
  'catalogAdminDomain.mdPlatformList.modal.input.query': 'Query:',
  'catalogAdminDomain.mdPlatformDetails.title': 'Details',
  'catalogAdminDomain.mdPlatformDetails.notify.errorMessage':
    'Momentary network issue occurred. If this persists, please contact support.',
  'catalogAdminDomain.mdPlatformDetails.table.version': 'Version',
  'catalogAdminDomain.mdPlatformDetails.table.status': 'Status',
  'catalogAdminDomain.mdPlatformDetails.table.comments': 'Comments',
  'catalogAdminDomain.mdPlatformDetails.table.createdBy': 'Created By',
  'catalogAdminDomain.mdPlatformDetails.table.createdReason': 'Created Reason',
  'catalogAdminDomain.mdPlatformDetails.table.changes': 'Changes',
  'catalogAdminDomain.mdPlatformDetails.table.actions': 'Actions',
  'catalogAdminDomain.mdPlatformDetails.tab.properties': 'Properties',
  'catalogAdminDomain.mdPlatformDetails.tab.changes': 'Changes',
  'catalogAdminDomain.mdPlatform.versions.state': 'State:',
  'catalogAdminDomain.mdPlatform.versions.enabled': 'Enabled:',
  'catalogAdminDomain.mdPlatformDetails.approve': 'Approve',
  'catalogAdminDomain.mdPlatformDetails.reject': 'Reject',
  'catalogAdminDomain.mdPlatformDetails.tombstoned': 'Tombstoned',
  'catalogAdminDomain.mdPlatformDetails.tombstone': 'Tombstone',
  'catalogAdminDomain.mdPlatformDetails.tombstoneWarning':
    'You will permanently undo the changes made from this version.',
  'catalogAdminDomain.mdPlatformDetails.previous': 'Previous',
  'catalogAdminDomain.mdPlatformDetails.next': 'Next',
  'catalogAdminDomain.mdPlatformDetails.modal.title': 'Confirm',
  'catalogAdminDomain.mdPlatformDetails.modal.cancel': 'Cancel',
  'catalogAdminDomain.mdPlatformDetails.modal.submit': 'Submit',
  'catalogAdminDomain.mdPlatformDetails.modal.reason': 'State Reason',
} as const

export default EN_US

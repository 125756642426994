export const EN_US = {
  'customersDomain.search.pageTitle': 'Search Customers',
  'customersDomain.search.fullName': 'Full Name',
  'customersDomain.search.emailAddress': 'Email Address',
  'customersDomain.search.phoneNumber': 'Phone Number',
  'customersDomain.search.membershipNumber': 'Membership Number',
  'customersDomain.search.placeholder': 'Search email...',
  'customersDomain.search.noResultsMessage': 'No customers found',
  'customersDomain.search.newSearchMessage': 'Enter an email address above to find a customer',
  'customersDomain.search.searchingMessage': 'Searching...',
  'customersDomain.search.requestError': 'Hmm, something went wrong',
  'customersDomain.search.bannerSubtitle': 'Find a customer',
  'customersDomain.search.bannerDescription': 'Search by email or phone number',
  'customersDomain.search.email': 'Email',
  'customersDomain.search.emailPlaceholder': 'Search by Email',
  'customersDomain.search.emailNewSearch': 'Enter an email address above to find a customer',
  'customersDomain.search.phone': 'Phone',
  'customersDomain.search.phonePlaceholder': 'Search by Phone',
  'customersDomain.search.phoneNewSearch': 'Enter a phone number above to find a customer',
  'customersDomain.search.order': 'Order ID',
  'customersDomain.search.orderPlaceholder': 'Search by Order Number',
  'customersDomain.search.orderNewSearch': 'Enter an order number above to find a customer',
  'customersDomain.search.loyaltyNumber': 'Loyalty #',
  'customersDomain.search.loyaltyNumberPlaceholder': 'Search by Loyalty Number',
  'customersDomain.search.loyaltyNumberNewSearch':
    'Enter a loyalty number above to find a customer',

  'customersDomain.customer.tabs.details': 'Details',
  'customersDomain.customer.tabs.privacyRequests': 'Privacy Requests',

  'customersDomain.customer.notFound': 'Customer not found',
  'customersDomain.customer.menu.manageCustomer': 'Manage customer',
  'customersDomain.customer.actions.createPrivacyRequest': 'Create privacy request',
  'customersDomain.customer.actions.goToOrders': 'Go to orders',
  'customersDomain.customer.actions.grantCredits': 'Grant credits',
  'customersDomain.customer.actions.impersonateUser': 'Log in as customer',
  'customersDomain.customer.actions.impersonateUserError':
    'An error occurred trying to impersonate',
  'customersDomain.appeasement.creditSent.success': 'Credit amount of {amount} was sent to {name}.',
  'customersDomain.customer.modal.grantCredits': 'Grant credits',
  'customersDomain.customer.modal.reviewAndSubmit': 'Confirmation of credit grant',
  'customersDomain.customer.modal.back': 'Back',
  'customersDomain.customer.modal.sendCredit': 'Send credit',
  'customersDomain.customer.modal.next': 'Next',
  'customersDomain.customer.modal.cancel': 'Cancel',

  'customersDomain.customer.appeasement.select': 'Select...',
  'customersDomain.customer.appeasement.helperText':
    'Credits are non-reversible and permanent once granted.',
  'customersDomain.customer.appeasement.selectReason': 'Select a reason*',
  'customersDomain.customer.appeasement.reasons.OrderIssue': 'Order issue',
  'customersDomain.customer.appeasement.reasons.PickingProblem': 'Picking problem',
  'customersDomain.customer.appeasement.reasons.DeliveryProblem': 'Delivery problem',
  'customersDomain.customer.appeasement.reasons.CouponProblem': 'Coupon problem',
  'customersDomain.customer.appeasement.reasons.DiscountProblem': 'Discount problem',
  'customersDomain.customer.appeasement.reasons.CustomerIssue': 'Customer issue',
  'customersDomain.customer.appeasement.reasons.RetailerMistake': 'Retailer mistake',
  'customersDomain.customer.appeasement.reasons.CustomerMistake': 'Customer mistake',
  'customersDomain.customer.appeasement.reasons.GoodWill': 'Good will',
  'customersDomain.customer.appeasement.reasons.Other': 'Other',
  'customersDomain.customer.appeasement.amount.selectAmount': 'Choose appeasement amount*',
  'customersDomain.customer.appeasement.amount.enterCustomAmount': 'Enter custom amount',
  'customersDomain.customer.appeasement.amount.five': '$5',
  'customersDomain.customer.appeasement.amount.ten': '$10',
  'customersDomain.customer.appeasement.amount.fifteen': '$15',
  'customersDomain.customer.appeasement.amount.custom': 'Custom',
  'customersDomain.customer.appeasement.amount.required': 'Amount is required',
  'customersDomain.customer.appeasement.amount.isNumber': 'Must be a number',
  'customersDomain.customer.appeasement.amount.minAmount': 'Amount must be greater than $0',
  'customersDomain.customer.appeasement.amount.exceedsCreditGrantLimit':
    'Amount exceeds credit grant limit, please contact support.',
  'customersDomain.customer.appeasement.amount.exceedsCreditBalanceLimit':
    'Customer account balance would exceed ${balanceLimit}, please contact support.',
  'customersDomain.customer.appeasement.reason.required': 'Reason is required',
  'customersDomain.customer.appeasement.additionalComments': 'Additional comments',
  'customersDomain.customer.appeasement.additionalComments.optional': '(optional)',
  'customersDomain.customer.appeasement.duplicateAlert':
    'This customer was recently granted credits in the amount of ${amount} on {date} at {time}. Are you sure you want to grant additional credits in the amount of ${amount}?',

  'customersDomain.customer.appeasement.review.confirmation': 'Confirmation of credit grant',
  'customersDomain.customer.appeasement.review.reason': 'Reason:',
  'customersDomain.customer.appeasement.review.appeasementAmount': 'Appeasement amount:',
  'customersDomain.customer.appeasement.review.additionalComments': 'Additional comments:',
  'customersDomain.customer.appeasement.review.saved':
    'This transaction will be saved and can be found under the Customer credits section under Customer details.',

  'customersDomain.customer.appeasement.table.customerCredits': 'Customer credits',
  'customersDomain.customer.appeasement.table.na': 'N/A',
  'customersDomain.customer.appeasement.table.dateIssued': 'Date Issued',
  'customersDomain.customer.appeasement.table.reason': 'Reason',
  'customersDomain.customer.appeasement.table.amount': 'Amount',
  'customersDomain.customer.appeasement.table.currency': '$',
  'customersDomain.customer.appeasement.table.notes': 'Notes',
  'customersDomain.customer.appeasement.table.balance': 'Customer credit balance',
  'customersDomain.customer.appeasement.table.empty':
    'No appeasement records associated with this customer',
  'customersDomain.customer.appeasement.table.requestError': 'Hmm, something went wrong',

  'customersDomain.privacyRequests.pageTitle': 'Customer privacy requests',
  'customersDomain.privacyRequests.requestDate': 'Request Date',
  'customersDomain.privacyRequests.customerEmail': 'Customer Email',
  'customersDomain.privacyRequests.customerId': 'Customer ID',
  'customersDomain.privacyRequests.requestType': 'Request Type',
  'customersDomain.privacyRequests.status': 'Status',
  'customersDomain.privacyRequests.download': 'Download',
  'customersDomain.privacyRequests.retailerNotes': 'Additional Notes',
  'customersDomain.privacyRequests.allStatuses': 'All Statuses',
  'customersDomain.privacyRequests.allRequestTypes': 'All Request Types',
  'customersDomain.privacyRequests.noResultsMessage': 'No results',
  'customersDomain.privacyRequests.requestError': 'Hmm, something went wrong',

  'customersDomain.requestType.access': 'Data access',
  'customersDomain.requestType.deletion': 'Data deletion',
  'customersDomain.requestType.unspecified': 'Unspecified',

  'customersDomain.workflowStatus.completed': 'Completed',
  'customersDomain.workflowStatus.deactivate': 'Deactivate',
  'customersDomain.workflowStatus.expired': 'Expired',
  'customersDomain.workflowStatus.failed': 'Failed',
  'customersDomain.workflowStatus.getData': 'Get Data',
  'customersDomain.workflowStatus.inProgress': 'In Progress',
  'customersDomain.workflowStatus.readyToDelete': 'Ready To Delete',
  'customersDomain.workflowStatus.unspecified': 'Unspecified',

  'customersDomain.createPrivacyRequest.header': 'Create privacy request',
  'customersDomain.createPrivacyRequest.requestType': 'Request Type',
  'customersDomain.createPrivacyRequest.dataAccess': 'Data access',
  'customersDomain.createPrivacyRequest.dataDeletion': 'Data deletion',
  'customersDomain.createPrivacyRequest.cancel': 'Cancel',
  'customersDomain.createPrivacyRequest.createRequestButton': 'Create Request',
  'customersDomain.createPrivacyRequest.additionalComment': 'Additional comment',
  'customersDomain.createPrivacyRequest.optional': '(optional)',
  'customersDomain.createPrivacyRequest.acknowledge': 'Acknowledge',
  'customersDomain.createPrivacyRequest.acknowledgeLabel':
    'I acknowledge that this is a verified request from the customer.',
  'customersDomain.createPrivacyRequest.customer': 'Customer',
  'customersDomain.createPrivacyRequest.userId': 'User ID',
  'customersDomain.createPrivacyRequest.privacyRequestCreated':
    'Privacy request created successfully',
  'customersDomain.createPrivacyRequest.privacyRequestError':
    'Privacy request creation failed with status: {status}',
  'customersDomain.createPrivacyRequest.privacyRequestGenericError':
    'Privacy request creation failed with network or graph error',
  'customersDomain.createPrivacyRequest.retailerNotes': 'Additional Notes',

  'customersDomain.customerDetails.firstName': 'First name',
  'customersDomain.customerDetails.lastName': 'Last name',
  'customersDomain.customerDetails.emailAddress': 'Email address',
  'customersDomain.customerDetails.phoneNumber': 'Phone number',
  'customersDomain.customerDetails.deliveryAddress': 'Delivery address',
  'customersDomain.customerDetails.lastStoreShopped': 'Last store shopped',
  'customersDomain.customerDetails.loyaltyCard': 'Loyalty card',
  'customersDomain.customerDetails.icPlus': 'IC+ member',
  'customersDomain.customerDetails.icPlus.true': 'Yes',
  'customersDomain.customerDetails.icPlus.false': 'No',
  'customersDomain.customerDetails.notApplicable': 'N/A',
  'customersDomain.customerDetails.editDetails': 'Edit details',
  'customersDomain.customerPrivacyRequests.empty':
    'No privacy requests associated with this customer',

  'customersDomain.retailerNotes.header': 'Privacy Request Notes',
  'customersDomain.retailerNotes.date': 'Request Date',
  'customersDomain.retailerNotes.notes': 'Additional Notes',
  'customersDomain.retailerNotes.close': 'Close',
} as const

export default EN_US

import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureEntitlements } from './featureEntitlements'

export const minMoreAndHamburgerMenuVersion = ICPlatformVersion.r2025_02_0
export const minHeaderNavAndStylingEditVersion = ICPlatformVersion.r2025_01_0
// TODO, remove after R2
const HORNBACHERS = '1230'
const PCNY = '1250'
export const tempPreR2ReleasePartners = [HORNBACHERS, PCNY]
export const legacyReadOnlyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ReadOnly],
}

export const siteManagementNavigationAccessControl = {
  featureEntitlementIds: [FeatureEntitlements.Navigation],
  permissions: [Permission.StoreConfigsView, Permission.StorefrontNavigationView],
} satisfies DomainAccessControlConfig

export const siteManagementNavigationModifyAccessControl = {
  featureEntitlementIds: [FeatureEntitlements.Navigation],
  permissions: [Permission.StoreConfigsModify],
} satisfies DomainAccessControlConfig

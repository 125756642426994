import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState } from 'react'
import { PopoverTrigger } from '@retailer-platform/shared-components'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Popover } from '@mantine/core'
import styled from '@emotion/styled'
import { type ViewType } from '../retailer-scope-wrapper/RetailerScopeWrapper'
import { Divider } from './Common'
import PickerLabel from './PickerLabel'
import { getKey, getLeafNodes } from './utils'
import { type Option } from './utils'
import MultiScopeDropdownOptionContainer from './MultiScopeDropdownOptionContainer'
import ApplyCancelComponent from './ApplyCancelComponent'
import { type PopoverConfig } from './Common'

const PopoverContainer = styled.div({
  flexDirection: 'column',
  margin: '10px 0px',
})

export interface Props {
  options: Option[]
  multiSelect: boolean
  scopeSelectionOptional?: boolean
  openByDefault?: boolean
  onSingleSelect: (option: Option) => void
  onMultiSelect: (options: Option[]) => void
  selectionType: ViewType
  popoverConfig?: PopoverConfig
  defaultToAll?: boolean
}

const Container = styled.div({})

const OverflowContainer = styled.div<{ maxHeight: number }>(({ maxHeight }) => ({
  maxHeight: maxHeight,
  overflow: 'auto',
  padding: '8px 0px',
}))

const renderOptions = (
  options: Option[],
  multiSelect: boolean,
  selectedState: Record<string, Option>,
  setSelectedState: (state: Record<string, Option>) => void,
  onSingleSelect: (option: Option) => void
) =>
  options.map(option => (
    <div key={option.id}>
      <MultiScopeDropdownOptionContainer
        option={option}
        multiSelect={multiSelect}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        onSingleSelect={onSingleSelect}
      />
    </div>
  ))

const MultiScopeDropdownComponent: FunctionComponent<PropsWithChildren<Props>> = ({
  options,
  multiSelect,
  scopeSelectionOptional,
  openByDefault,
  onSingleSelect,
  onMultiSelect,
  selectionType,
  popoverConfig,
  defaultToAll,
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault)

  const leafNodes = getLeafNodes(options)
  const defaultSelectedState: Record<string, Option> = {}

  leafNodes.forEach(node => {
    if (node.selected) {
      defaultSelectedState[getKey(node)] = node
    }
  })

  const [selectedState, setSelectedState] = useState<Record<string, Option>>(defaultSelectedState)
  const [tempSelectedState, setTempSelectedState] =
    useState<Record<string, Option>>(defaultSelectedState)

  const onClickAccept = () => {
    setIsOpen(false)
    setTempSelectedState(selectedState)
    if (Object.values(selectedState).length > 0) {
      onMultiSelect(Object.values(selectedState).filter(e => e.leafNode))
    } else if (defaultToAll) {
      // Only select all if defaultToAll is true
      // nothing has been selected, which impllies that all options should be selected
      const leafNodes = getLeafNodes(options)
      onMultiSelect(leafNodes)
    } else {
      onMultiSelect([]) // Otherwise return empty selection
    }
  }

  const onClear = () => {
    if (defaultToAll) {
      // Only select all if defaultToAll is true
      // nothing has been selected, which impllies that all options should be selected
      const leafNodes = getLeafNodes(options)
      onMultiSelect(leafNodes)
    } else {
      onMultiSelect([]) // Otherwise return empty selection
    }
  }

  const onClickCancel = () => {
    setSelectedState(tempSelectedState)
    setIsOpen(false)
  }

  const handlePopoverChange = () => {
    if (isOpen) {
      setSelectedState(tempSelectedState)
      setIsOpen(false)
    }
  }

  const onSingleSelectInternal = (option: Option) => {
    setIsOpen(false)
    onSingleSelect(option)
  }

  const height = popoverConfig?.maxHeight ?? 700
  const position = popoverConfig?.position ?? 'bottom-start'
  const offset = popoverConfig?.offset ?? 4
  const width = popoverConfig?.width ?? 'max-content'
  const withinPortal = popoverConfig?.withinPortal ?? true
  return (
    <Popover
      opened={isOpen}
      onChange={handlePopoverChange}
      position={position}
      offset={offset}
      zIndex={90}
      withinPortal={withinPortal}
      width={width}
    >
      <Popover.Target>
        <PopoverContainer>
          <PopoverTrigger
            css={{
              padding: '6px 8px 6px 8px',
              gap: '8px',
            }}
            onClick={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            data-testid="multi-scope-picker-trigger"
          >
            <PickerLabel
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              multiSelect={multiSelect}
              onClear={onClear}
              selectionType={selectionType}
              scopeSelectionOptional={scopeSelectionOptional}
              defaultToAll={defaultToAll}
              setTempSelectedState={setTempSelectedState}
            ></PickerLabel>
          </PopoverTrigger>
        </PopoverContainer>
      </Popover.Target>

      <Popover.Dropdown style={{ padding: '0px' }}>
        <Container>
          <OverflowContainer maxHeight={height}>
            {renderOptions(
              options,
              multiSelect,
              selectedState,
              setSelectedState,
              onSingleSelectInternal
            )}
          </OverflowContainer>

          {multiSelect && (
            <>
              <Divider />
              <ApplyCancelComponent onClickAccept={onClickAccept} onClickCancel={onClickCancel} />
            </>
          )}
        </Container>
      </Popover.Dropdown>
    </Popover>
  )
}

export default MultiScopeDropdownComponent

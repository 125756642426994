import { type Moment } from 'moment'
import {
  type Instacart_Customers_Shared_V1_Locale,
  type Scalars,
  type Maybe,
  type InputMaybe,
  type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToCollection,
  type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToFlyerPage,
  type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToRecipe,
  type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToUrl,
  type Instacart_Customers_Retailer_Collections_Admin_V1_OpenFlyerActivity,
} from './api'

export enum SearchTypes {
  Name = 'name',
  RRC = 'rrc',
  UPC = 'upc',
  ProductId = 'productId',
  Omni = 'omni',
  Multi = 'multi',
}

export enum SearchError {
  TooManySearchTerms = 'Too many search terms',
  InvalidProductId = 'Invalid product id',
  MissingSearchTerm = 'Missing search term',
}

export enum RuleValueType {
  Boolean = 'BOOLEAN',
  IntegerMultiselect = 'INTEGER_MULTISELECT',
  IntegerSelect = 'INTEGER_SELECT',
  RangeFloat = 'RANGE_FLOAT',
  StringMultiselect = 'STRING_MULTISELECT',
  StringSelect = 'STRING_SELECT',
  Text = 'TEXT',
  Integer = 'INTEGER',
}

export enum CollectionType {
  Static = 'STATIC',
  Dynamic = 'DYNAMIC',
  DynamicallyFedStatic = 'DYNAMICALLY_FED_STATIC',
}

export enum RetailerCollectionSortOrder {
  BestMatch = 'BEST_MATCH',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EnumTypeSortOrderUnspecified = 'ENUM_TYPE_SORT_ORDER_UNSPECIFIED',
  Popularity = 'POPULARITY',
  ManualOrder = 'MANUAL_ORDER',
}

export enum RetailerCollectionOrderBy {
  EnumTypeOrderByUnspecified = 'ENUM_TYPE_ORDER_BY_UNSPECIFIED',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

export enum RetailerCollectionOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  EnumTypeOrderDirectionUnspecified = 'ENUM_TYPE_ORDER_DIRECTION_UNSPECIFIED',
}

export enum RetailerCollectionRuleSupportedQuery {
  BrandNames = 'BRAND_NAMES',
  EnumTypeRuleValueSourceQueryUnspecified = 'ENUM_TYPE_RULE_VALUE_SOURCE_QUERY_UNSPECIFIED',
  TaxonomyNodes = 'TAXONOMY_NODES',
  RetailerDepartments = 'RETAILER_DEPARTMENTS',
}

export enum RetailerCollectionRuleValueSourceType {
  EnumTypeRuleValueSourceTypeUnspecified = 'ENUM_TYPE_RULE_VALUE_SOURCE_TYPE_UNSPECIFIED',
  Query = 'QUERY',
  Static = 'STATIC',
}

export type RetailerCollectionFilter = {
  __typename?: 'RetailerCollectionFilter'
  collectionId: Maybe<Scalars['BigInt']>
  id: Maybe<Scalars['BigInt']>
  intersectionGroup: Maybe<Scalars['BigInt']>
  ruleId: Maybe<Scalars['BigInt']>
  value: Maybe<Scalars['String']>
}

export type ProductInput = {
  cachedProductId?: InputMaybe<Scalars['BigInt']>
  id?: InputMaybe<Scalars['BigInt']>
  lookupCode?: InputMaybe<Scalars['String']>
  pinned?: InputMaybe<Scalars['Boolean']>
  position?: InputMaybe<Scalars['BigInt']>
  retailerReferenceCode?: InputMaybe<Scalars['String']>
}

export type RetailerCollectionsCsvError = {
  __typename?: 'RetailerCollectionsCsvError'
  errorMessage: Maybe<Scalars['String']>
  identifiers: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface RetailerProductDatum {
  primaryImageUrl?: string
  lookupCode?: string | null
  productDisplayName?: string
  productId?: string
  retailerReferenceCode?: string
  inCollection: boolean
  retailerCollectionAssignmentId?: string
  retailerId?: string
  retailerIds?: string[]
}

export interface RetailerProductSearchParams {
  retailerId: string
  searchTerm: string
  searchType: string
  page: number
  locale: string
  limit: number
  retailerIds?: string[]
  productCodes?: string[]
  retailerReferenceCodes?: string[]
}

export interface RetailerProductSearchParamsV2 {
  retailerIds: string[]
  searchTerm: string
  searchType: string
  page: number
  locale: string
  limit: number
  productCodes?: string[]
  retailerReferenceCodes?: string[]
}

export interface RetailerProduct {
  id?: string
  productId?: string
  lookupCode?: string | null
  matched: boolean
  name?: string
  primaryImageUrl?: string
  retailerReferenceCode?: string
  selected?: boolean
  pinned?: boolean
  position?: string
  retailerId?: string
  retailerIds?: string[]
}

export enum RetailerCollectionStatus {
  Active = 'ACTIVE',
  ActiveAdminOnly = 'ACTIVE_ADMIN_ONLY',
  EnumTypeStatusUnspecified = 'ENUM_TYPE_STATUS_UNSPECIFIED',
  Inactive = 'INACTIVE',
}

export type Filter = {
  __typename?: 'Filter'
  collectionId: Maybe<Scalars['BigInt']>
  id: Maybe<Scalars['BigInt']>
  intersectionGroup: Maybe<Scalars['BigInt']>
  ruleId: Maybe<Scalars['BigInt']>
  value: Maybe<Scalars['String']>
}

export type RetailerCollectionProductPayloadInput = {
  id?: InputMaybe<Scalars['BigInt']>
  lookupCode?: InputMaybe<Scalars['String']>
  rrc?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
}

export interface Collection {
  id: string
  name: string
  slug: string
  defaultSortOrder: RetailerCollectionSortOrder | null
  productCount: string
  createdAt: string
  updatedAt: string
  productIds: Array<string>
  dynamicallyFedStatic: boolean
  source?: string
  translations: Array<CollectionTranslation>
  filters: Array<CollectionFilter>
  inventoryFileMappings?: Array<InventoryFileMapping>
  collectionType?: string
  retailerId?: string
  partnerMapping?: PartnerMapping | null
}

export interface Flyer {
  id: string
  name: string
  tag: string
  status: string
  startsAt: string
  endsAt: string
  versionCode?: string
  storeCount?: number
  pages: FlyerPage[]
}

export interface FlyerPage {
  id: string
  pageNumber: number
  image: string
  flyerId: string
}

export type NavigateToRecipe = Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToRecipe
export type NavigateToCollection =
  Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToCollection
export type NavigateToUrl = Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToUrl
export type NavigateToFlyerPage =
  Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToFlyerPage
export type OpenFlyerActivity = Instacart_Customers_Retailer_Collections_Admin_V1_OpenFlyerActivity

export type FlyerClickableAreaAction =
  | NavigateToRecipe
  | NavigateToCollection
  | NavigateToUrl
  | NavigateToFlyerPage
  | OpenFlyerActivity
  | null

export enum FlyerClickableAreaActionEnum {
  NavigateToRecipe = 'NavigateToRecipe',
  NavigateToCollection = 'NavigateToCollection',
  NavigateToUrl = 'NavigateToUrl',
  NavigateToFlyerPage = 'NavigateToFlyerPage',
  OpenFlyerActivity = 'OpenFlyerActivity',
  Unknown = 'Unknown',
}

export interface FlyerClickableArea {
  id: string
  pageId: string
  active: boolean
  dimensions: {
    x: number
    y: number
    height: number
    width: number
    percent: {
      x: number
      y: number
      width: number
      height: number
    }
  }
  actionType: FlyerClickableAreaActionEnum
  action: FlyerClickableAreaAction
}

export interface FlyerActivity {
  id: string
  title: string
  description: string
  pageId: string
  collectionId: string
  externalActivityId: string
}

// Order Matters
export enum FlyerStatus {
  Unknown = 1,
  Ended,
  Draft,
  Preview,
  Active,
  Scheduled,
  Cancelled,
}

export enum CollectionSourceType {
  Retailer = 'RETAILER',
  Instacart = 'INSTACART',
}

export interface FlyerDatum extends Flyer {
  groupId: string
  groupHeader: boolean
  collapsed: boolean
}

export interface FlyerGroup {
  id: string
  sortKey: number
  startsAt: Moment
  endsAt: Moment
  name: string
  tag: string
  flyers: Flyer[]
}

export interface CollectionTranslation {
  localeCode: Instacart_Customers_Shared_V1_Locale
  name: string
}

export interface CollectionProduct {
  id: string
  cachedProductId: string
  lookupCode?: string
  retailerReferenceCode?: string
  pinned?: boolean
  position?: string
}

export interface CollectionFilter {
  id: string
  ruleId: string
  value: string
  intersectionGroup: string
}

export interface CollectionRule {
  id: string
  name: string
  slug: string
  valueType: RuleValueType
  valueSource?: {
    type: string
    options?:
      | {
          label: string
          value: string
        }[]
      | null
    query?: string | null
  } | null
}

export interface InventoryFileMapping {
  id: string
  retailerCollectionId: string
  retailerId: string
  collectionSlug?: string
}

export interface PartnerMapping {
  id: string
  partnerId: string | null
  collectionSlug: string
  retailerIds: string[]
}
